export const state = () => ({
	entity: process.env.NUXT_ENV_DEFAULT_ENTITY,
});

export const mutations = {
	setEntity(state, payload) {
		// setKey("debugEntity", payload);
		state.entity = payload;
	},
};

export const actions = {};
