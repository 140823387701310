import Vue from "vue";
import Router from "vue-router";
import cloneDeep from "clone-deep";
// import scrollBehavior from "./scrollBehavior.js";
import noLocaleRoutes from "./no-locale-routes";
import locales from "~/tmp/locales.json";
import routes from "~/routes/routes.js";
Vue.use(Router);

function localizeRoutes(routes, locale) {
	const localizedRoutes = cloneDeep(routes).map((route) => {
		const path = route.path.replace(/^\//, "");
		route.path = "/" + locale + (path ? "/" + path : "");
		route.name = route.name + "___" + locale;
		if (route.children) {
			route.children = localizeRoutes(route.children, locale);
		}
		return route;
	});

	return localizedRoutes;
}



export function createRouter(ssrContext, createDefaultRouter, routerOptions, $config, store) {
	let newRoutes = [];

	const codes = locales.map(({ code }) => code);

	codes.forEach((code) => {
		const localizedRoutes = localizeRoutes(routes, code);

		newRoutes = [...newRoutes, ...localizedRoutes];
	});

	newRoutes = [...newRoutes, ...noLocaleRoutes];

	return new Router({
		mode: "history",
		routes: newRoutes,
		// scrollBehavior() {

		// },
	});
}
