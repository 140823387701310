// let createClient;

// if (process.server) {
// 	createClient = function () {
// 		const Redis = require('ioredis');
// 		const redisUrl = process.env.NUXT_ENV_REDIS_URL;
// 		//  set auto reconnect to false
// 		const redisClient = new Redis({
// 			url: redisUrl,
// 			reconnectOnError() {
// 				return false;
// 			},
// 		});




// 		redisClient.on('connect', () => {
// 			console.log('axios Client connected to redis...')
// 			// close connection after 3 seconds of idle
// 			redisClient.send_command('config', ['set', 'timeout', 3]);


// 		})

// 		redisClient.on('ready', () => {
// 			console.log('axios Client connected to redis and ready to use...')
// 		})

// 		redisClient.on('error', (err) => {
// 			console.log('CLIENT...', err.message)
// 		})

// 		redisClient.on('end', () => {
// 			console.log('Client disconnected from redis')
// 		})

// 		return redisClient;
// 	}

// }
// const redisTimeout = 3000;
export default function (ctx) {
	const { $axios, store, req, i18n, $logError, $cookies } = ctx

	$axios.$getOnce = function (url, config) {
		const fullPath = $axios.getUri({ url, params: config?.params });

		if (store.getters["cache/isPromiseExist"](fullPath)) {
			console.log("cache hit", store.getters["cache/getPromise"](fullPath));
			return store.getters["cache/getPromise"](fullPath);
		}
		if (store.getters["cache/isKeyExist"](fullPath)) {
			console.log("cache hit", Promise.resolve(store.getters["cache/getKey"](fullPath)));
			return new Promise((resolve) => {
				resolve(store.getters["cache/getKey"](fullPath).value);
			});
		} else {
			const promise = this.$get(url, { ...config, headers: { ...config?.headers } }).then((response) => {
				store.dispatch("cache/insertKey", { key: fullPath, value: response });
				return response;
			});

			store.dispatch("cache/insertPromise", { key: fullPath, value: promise });
			return promise;
		}
	};


	$axios.onRequest((apiReq) => {
		if (!apiReq.headers.common) {
			apiReq.headers.common = {};
		}
		apiReq.headers.common.Entity = $cookies.get('axios_entity') || store.state.entity;
		if (i18n.locale) {
			apiReq.headers.common.Language = i18n.locale;
		}
		if (req?.headers?.["x-forwarded-for"]) {
			apiReq.headers.common["Client-ip"] = req?.headers?.["x-forwarded-for"];
		}

		if (process.server) {
			apiReq.headers.common["is-ssr"] = "true";
		}

	});

	$axios.onError((e) => {
		// print url, status, headers sent, body sent, resp.data.message
		const errorDetails = {
			method: e.config.method,
			baseURL: e.config.baseURL,
			url: e.config.url,
			status: e.response?.status,
			entity: e.config.headers.Entity,
			language: e.config.headers.Language,
			message: e.response?.data?.message,
		};
		$logError("Axios Error ", errorDetails);
	});

}
