
export default {
	name: "Toasts",

	props: {
		// objects: {
		// 	type: Array,
		// 	default: () => [],
		// },
		// x: {
		// 	type: String,
		// 	default: "right",
		// },
		// y: {
		// 	type: String,
		// 	default: "bottom",
		// },
		// centered: {
		// 	type: Boolean,
		// 	default: false,
		// },
		// color: {
		// 	type: String,
		// 	default: "info",
		// },
		// icon: {
		// 	type: String,
		// 	default: "",
		// },
		// iconColor: {
		// 	type: String,
		// 	default: "",
		// },
		// classes: {
		// 	type: [String, Object, Array],
		// 	default: "",
		// },
		// message: {
		// 	type: String,
		// 	default: "",
		// },
		// timeout: {
		// 	type: Number,
		// 	default: 3000,
		// },
		// dismissable: {
		// 	type: Boolean,
		// 	default: true,
		// },
		// multiLine: {
		// 	type: Boolean,
		// 	default: false,
		// },
		// vertical: {
		// 	type: Boolean,
		// 	default: false,
		// },
		// showClose: {
		// 	type: Boolean,
		// 	default: false,
		// },
		// closeText: {
		// 	type: String,
		// 	default: "",
		// },
		// closeIcon: {
		// 	type: String,
		// 	default: "close",
		// },
		// closeColor: {
		// 	type: String,
		// 	default: "",
		// },
		// title: {
		// 	type: String,
		// 	default: "",
		// },
	},
	data: () => ({
		// active: false,
		// remainingTimeout: 0,
		objects: [],
	}),

	mounted() {
		// this.$nextTick(() => this.show());
	},
	methods: {
		// show() {
		// 	this.active = true;

		// 	if (this.timeout) {
		// 		const precision = 100;
		// 		this.remainingTimeout = this.timeout;
		// 		const int = setInterval(() => {
		// 			this.remainingTimeout -= precision;

		// 			if (this.remainingTimeout <= 0) {
		// 				clearInterval(int);
		// 				this.close();
		// 			}
		// 		}, precision);
		// 	}
		// },
		// close() {
		// 	this.active = false;
		// },
		// dismiss() {
		// 	if (this.dismissable) {
		// 		this.close();
		// 	}
		// },
		add(obj) {
			this.objects.push(obj);
		},
	},
};
