// serialize query with its type

const serialize = (obj) => {
	const str = [];
	for (const p in obj)
		if (Object.prototype.hasOwnProperty.call(obj, p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return str.join("&");
};

const deserialize = (query) => {
	console.log("🚀 ~ file: QuerySerialization.js ~ line 15 ~ deserialize ~ query", query);

	// for (const key in query) {
	// 	const [type, value] = query[key].split(separator);
	// 	switch (type) {
	// 		case "N":
	// 			query[key] = Number(value);
	// 			break;
	// 		case "B":
	// 			query[key] = value === "true";
	// 			break;

	// 		default:
	// 		//	query[key] = value;
	// 	}
	// }
	// return query;
};

export { serialize, deserialize };
