import scrollBehavior from "~/routes/scrollBehavior";

export default function ({ app, $vuetify, i18n, store, $dayjs }, inject) {
	// onBeforeLanguageSwitch called right before setting a new locale
	// app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
	// 	app.nuxt.defaultTransition.name = "fade-transition";
	// };

	function languageSwitchHandler(oldLocale, newLocale) {
		$vuetify.rtl = i18n.localeProperties.dir === "rtl";
		// import(`dayjs/locale/${newLocale}.js`).then(() => {
			// $dayjs.locale(newLocale);
			// console.log("onLanguageSwitched", $dayjs.weekdays());
		// });

		// console.log("onLanguageSwitched", $dayjs.locale());
		// await store.dispatch("loadStartupData", { i18n });
	}
	// onLanguageSwitched called right after a new locale has been set

	app.i18n.onLanguageSwitched = languageSwitchHandler;

	// app.nuxt.defaultTransition.beforeEnter = async (html) => {
	// 	await app.i18n.finalizePendingLocaleChange();
	// };

	// // Optional: wait for locale before scrolling for a smoother transition
	app.router.options.scrollBehavior = async (to, from, savedPosition) => {
		// Make sure the route has changed
		if (to.name !== from.name) {
			// app.nuxt.defaultTransition.name = "fade-transition";
			await app.i18n.waitForPendingLocaleChange();
		}
		return scrollBehavior(to, from, savedPosition);
	};
}
