
export default {
	data() {
		return {
			iHover: null,
			l1Hover: null,
		};
	},
	computed: {
		items() {
			return this.$store.state.mainMenu;
		},
	},
	methods: {
		// execute the function delayed by 100ms, but before the execution make sure the element is not hovered anymore
		delayed(func) {
			setTimeout(func, 100);
		},
	},
};
