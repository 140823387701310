import Vue from "vue";
import VueScrollReveal from "vue-scroll-reveal";
Vue.use(VueScrollReveal, {
	distance: "10%",
	origin: "bottom",

	delay: 0,

	duration: 1000,
	easing: "cubic-bezier(0.33, 1, 0.68, 1)",
	interval: 0,
	// opacity: 0.5,

	rotate: {
		x: 0,
		y: 0,
		z: 0,
	},
	scale: 1,
	cleanup: false,
	container: document.documentElement,
	desktop: true,
	mobile: true,
	reset: false,
	useDelay: "always",
	viewFactor: 0.0,
	viewOffset: {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
	afterReset(el) {},
	afterReveal(el) {},
	beforeReset(el) {},
	beforeReveal(el) {},
});
