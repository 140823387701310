
export default {
	name: "CampaignLayout",

	head() {
		const head = {
			script: [],
			link: [{ rel: "stylesheet", href: this.localeFont, as: "style" }],
			title: this.$store.state.currentEntity?.entity?.name || "",
			bodyAttrs: {
				dir: this.$i18n.localeProperties.dir,
				lang: this.$i18n.locale,
				class: "page-" + this.getRouteBaseName(),
			},
		};

		return head;
	},
	computed: {
		localeFont() {
			return "/fonts/" + this.$i18n.locale + ".css";
		},
	},
	beforeCreate() {
		this.$vuetify.rtl = this.$i18n.localeProperties.dir === "rtl";
		this.$vuetify.lang.current = this.$i18n.locale;
	},
	methods: {
		changeEntity(entity) {
			window.location = "//" + entity.affiliate_website.site_name;
		},
	},
};
