
export default {
	props: {
		errorObject: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			pageNotFound: "404 Not Found",
			otherError: "An error occurred",
		};
	},
	head() {
		const title = this.errorObject.statusCode === 404 ? this.pageNotFound : this.otherError;
		return {
			title,
		};
	},
};
