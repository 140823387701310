import Vue from "vue";

// import vuePositionSticky from "vue-position-sticky";

import responsive from "~/mixins/responsive";
import config from "~/mixins/config";
import errorHandler from "~/mixins/errorHandler";
import general from "~/mixins/general";

let localApp;

Vue.filter("date", (value) => {
	return (
		localApp
			.$dayjs(value)
			// .locale(app.i18n.locale || "ar")
			.format("YYYY-MM-DD")
	);
});
Vue.filter("dateTime", (value) => {
	if (!value) return "";
	return localApp.$dayjs(value).format("L LT");
});
Vue.filter("time", (value) => {
	return (
		localApp
			.$dayjs(value)
			// .locale(app.i18n.locale || "ar")
			.format("h:mm A")
	);
});
Vue.filter("niceDate", (value) => {
	return (
		localApp
			.$dayjs(value)
			// .locale(app.i18n.locale || "ar")
			.format("ll")
	);
});
Vue.filter("money", (value, currency = "USD") => {
	const formatter = new Intl.NumberFormat(localApp.i18n.localeProperties.iso, {
		style: "currency",
		currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return formatter.format(value);
});
Vue.filter("phone", (value) => {
	return value.replace(/\D+/g, "").replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 $2 $3$4");
});
Vue.filter("progress", (value) => {
	return `${Math.round(value * 10000) / 100}`;
});
Vue.mixin(responsive);
Vue.mixin(config);
Vue.mixin(errorHandler);
Vue.mixin(general);

export default ({ app }, inject) => {
	localApp = app;
};
