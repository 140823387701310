export const Ambassador = () => import('../../components/Ambassador.vue' /* webpackChunkName: "components/ambassador" */).then(c => wrapFunctional(c.default || c))
export const AvailablePaymentMethods = () => import('../../components/AvailablePaymentMethods.vue' /* webpackChunkName: "components/available-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const ChooseYourMarket = () => import('../../components/ChooseYourMarket.vue' /* webpackChunkName: "components/choose-your-market" */).then(c => wrapFunctional(c.default || c))
export const CopyTrading = () => import('../../components/CopyTrading.vue' /* webpackChunkName: "components/copy-trading" */).then(c => wrapFunctional(c.default || c))
export const CountDown = () => import('../../components/CountDown.vue' /* webpackChunkName: "components/count-down" */).then(c => wrapFunctional(c.default || c))
export const DubaiBanner = () => import('../../components/DubaiBanner.vue' /* webpackChunkName: "components/dubai-banner" */).then(c => wrapFunctional(c.default || c))
export const EducationalArticleCard = () => import('../../components/EducationalArticleCard.vue' /* webpackChunkName: "components/educational-article-card" */).then(c => wrapFunctional(c.default || c))
export const EducationalVideoCard = () => import('../../components/EducationalVideoCard.vue' /* webpackChunkName: "components/educational-video-card" */).then(c => wrapFunctional(c.default || c))
export const ErrorComponent = () => import('../../components/ErrorComponent.vue' /* webpackChunkName: "components/error-component" */).then(c => wrapFunctional(c.default || c))
export const FeaturedArticles = () => import('../../components/FeaturedArticles.vue' /* webpackChunkName: "components/featured-articles" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const HorizontalMenu = () => import('../../components/HorizontalMenu.vue' /* webpackChunkName: "components/horizontal-menu" */).then(c => wrapFunctional(c.default || c))
export const IngotCard = () => import('../../components/IngotCard.vue' /* webpackChunkName: "components/ingot-card" */).then(c => wrapFunctional(c.default || c))
export const InstrumentsTable = () => import('../../components/InstrumentsTable.vue' /* webpackChunkName: "components/instruments-table" */).then(c => wrapFunctional(c.default || c))
export const LaLiga = () => import('../../components/LaLiga.vue' /* webpackChunkName: "components/la-liga" */).then(c => wrapFunctional(c.default || c))
export const Mam = () => import('../../components/Mam.vue' /* webpackChunkName: "components/mam" */).then(c => wrapFunctional(c.default || c))
export const MarketAnalysisCandleChart = () => import('../../components/MarketAnalysisCandleChart.vue' /* webpackChunkName: "components/market-analysis-candle-chart" */).then(c => wrapFunctional(c.default || c))
export const MarketPrices = () => import('../../components/MarketPrices.vue' /* webpackChunkName: "components/market-prices" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../components/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const PromoteTrading = () => import('../../components/PromoteTrading.vue' /* webpackChunkName: "components/promote-trading" */).then(c => wrapFunctional(c.default || c))
export const RegistrationSteps = () => import('../../components/RegistrationSteps.vue' /* webpackChunkName: "components/registration-steps" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveVideo = () => import('../../components/ResponsiveVideo.vue' /* webpackChunkName: "components/responsive-video" */).then(c => wrapFunctional(c.default || c))
export const SectionTitle = () => import('../../components/SectionTitle.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const SidebarLayout = () => import('../../components/SidebarLayout.vue' /* webpackChunkName: "components/sidebar-layout" */).then(c => wrapFunctional(c.default || c))
export const SidebarList = () => import('../../components/SidebarList.vue' /* webpackChunkName: "components/sidebar-list" */).then(c => wrapFunctional(c.default || c))
export const SidebarTitle = () => import('../../components/SidebarTitle.vue' /* webpackChunkName: "components/sidebar-title" */).then(c => wrapFunctional(c.default || c))
export const StickySidebar = () => import('../../components/StickySidebar.vue' /* webpackChunkName: "components/sticky-sidebar" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const WeHelpYou = () => import('../../components/WeHelpYou.vue' /* webpackChunkName: "components/we-help-you" */).then(c => wrapFunctional(c.default || c))
export const WeKeepItSimple = () => import('../../components/WeKeepItSimple.vue' /* webpackChunkName: "components/we-keep-it-simple" */).then(c => wrapFunctional(c.default || c))
export const WebinarCard = () => import('../../components/WebinarCard.vue' /* webpackChunkName: "components/webinar-card" */).then(c => wrapFunctional(c.default || c))
export const WhoWeAre = () => import('../../components/WhoWeAre.vue' /* webpackChunkName: "components/who-we-are" */).then(c => wrapFunctional(c.default || c))
export const GoldCampaignHead = () => import('../../components/goldCampaignHead.vue' /* webpackChunkName: "components/gold-campaign-head" */).then(c => wrapFunctional(c.default || c))
export const IngotTrader = () => import('../../components/ingotTrader.vue' /* webpackChunkName: "components/ingot-trader" */).then(c => wrapFunctional(c.default || c))
export const FieldAutocomplete = () => import('../../components/Field/Autocomplete.vue' /* webpackChunkName: "components/field-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FieldCheckMark = () => import('../../components/Field/CheckMark.vue' /* webpackChunkName: "components/field-check-mark" */).then(c => wrapFunctional(c.default || c))
export const FieldCheckbox = () => import('../../components/Field/Checkbox.vue' /* webpackChunkName: "components/field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FieldChips = () => import('../../components/Field/Chips.vue' /* webpackChunkName: "components/field-chips" */).then(c => wrapFunctional(c.default || c))
export const FieldCountry = () => import('../../components/Field/Country.vue' /* webpackChunkName: "components/field-country" */).then(c => wrapFunctional(c.default || c))
export const FieldDate = () => import('../../components/Field/Date.vue' /* webpackChunkName: "components/field-date" */).then(c => wrapFunctional(c.default || c))
export const FieldDateTime = () => import('../../components/Field/DateTime.vue' /* webpackChunkName: "components/field-date-time" */).then(c => wrapFunctional(c.default || c))
export const FieldFile = () => import('../../components/Field/File.vue' /* webpackChunkName: "components/field-file" */).then(c => wrapFunctional(c.default || c))
export const FieldInput = () => import('../../components/Field/Input.vue' /* webpackChunkName: "components/field-input" */).then(c => wrapFunctional(c.default || c))
export const FieldLabel = () => import('../../components/Field/Label.vue' /* webpackChunkName: "components/field-label" */).then(c => wrapFunctional(c.default || c))
export const FieldPassword = () => import('../../components/Field/Password.vue' /* webpackChunkName: "components/field-password" */).then(c => wrapFunctional(c.default || c))
export const FieldPhone = () => import('../../components/Field/Phone.vue' /* webpackChunkName: "components/field-phone" */).then(c => wrapFunctional(c.default || c))
export const FieldRadio = () => import('../../components/Field/Radio.vue' /* webpackChunkName: "components/field-radio" */).then(c => wrapFunctional(c.default || c))
export const FieldSelect = () => import('../../components/Field/Select.vue' /* webpackChunkName: "components/field-select" */).then(c => wrapFunctional(c.default || c))
export const FieldTable = () => import('../../components/Field/Table.vue' /* webpackChunkName: "components/field-table" */).then(c => wrapFunctional(c.default || c))
export const FieldText = () => import('../../components/Field/Text.vue' /* webpackChunkName: "components/field-text" */).then(c => wrapFunctional(c.default || c))
export const FieldTextarea = () => import('../../components/Field/Textarea.vue' /* webpackChunkName: "components/field-textarea" */).then(c => wrapFunctional(c.default || c))
export const FieldUpload = () => import('../../components/Field/Upload.vue' /* webpackChunkName: "components/field-upload" */).then(c => wrapFunctional(c.default || c))
export const FieldUsers = () => import('../../components/Field/Users.vue' /* webpackChunkName: "components/field-users" */).then(c => wrapFunctional(c.default || c))
export const FieldYoutube = () => import('../../components/Field/Youtube.vue' /* webpackChunkName: "components/field-youtube" */).then(c => wrapFunctional(c.default || c))
export const ChartsLine = () => import('../../components/charts/Line.vue' /* webpackChunkName: "components/charts-line" */).then(c => wrapFunctional(c.default || c))
export const ToastsSnackbars = () => import('../../components/Toasts/Snackbars.vue' /* webpackChunkName: "components/toasts-snackbars" */).then(c => wrapFunctional(c.default || c))
export const Toasts = () => import('../../components/Toasts/index.vue' /* webpackChunkName: "components/toasts" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
