
export default {
	name: "EmptyLayout",
	layout: "empty",
	props: {
		error: {
			type: Object,
			default: null,
		},
	},
};
