export default ({ app, i18n, $sentry, $config }, inject) => {

    inject(
        "logError", function () {

            if ($sentry && !$config.isDev) {
                /* const eventId = */ $sentry.captureMessage(...arguments);
                if (process.server) {
                    console.error(...arguments);
                }
            } else {
                console.error(...arguments);
            }

        });
};