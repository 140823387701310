export default [
	// ****** Redirects ******
	{
		// https://www.ingotbrokers.com/en/market-alerts/blogs/866
		path: "/market-alerts/blogs/*",
		name: "market-alerts-blogs-redirect",
		redirect: "/news/blogs-and-trends",
	},
	{
		// https://www.ingotbrokers.com/en/tailored-trading/funds-management
		path: "/tailored-trading/funds-management",
		name: "tailored-trading-funds-management-redirect",
		redirect: "/deposits-and-withdrawals",
	},
	{
		// https://www.ingotbrokers.com/en/meet-your-broker/contact-us
		path: "/meet-your-broker/contact-us",
		name: "meet-your-broker-contact-us-redirect",
		redirect: "/contact-us",
	},
	{
		// https://www.ingotbrokers.com/en/tailored-trading/risk-management
		path: "/tailored-trading/risk-management",
		name: "tailored-trading-risk-management-redirect",
		redirect: "/legal-documents",
	},
	{
		// https://www.ingotbrokers.com/en/educational-articles
		path: "/educational-articles",
		name: "educational-articles-redirect",
		redirect: "/educational/articles",
	},
	{
		name: "redirect-open-account",
		path: "/accounts/open-account/:type/:referral?",
		component: () => import(/* webpackChunkName: "Auth" */ "~/pages/RedirectOpenAccount.vue").then((m) => m.default || m),
	},
	{
		name: "internal-transfer",
		path: "/online-forms/request-internal-transfer",
		component: () => import(/* webpackChunkName: "online-forms" */ "~/pages/InternalTransferRedirect.vue").then((m) => m.default || m),
	},
	{
		name: "redirect-login",
		path: "/login",
		component: () => import(/* webpackChunkName: "Auth" */ "~/pages/RedirectLogin.vue").then((m) => m.default || m),
	},

	{
		path: "/",
		name: "index",
		component: () => import(/* webpackChunkName: "home" */ "~/pages/Index.vue").then((m) => m.default || m),
	},
	{
		name: "why-ingot-brokers-old",
		path: "/about/why-ingot-brokers",
		redirect: "/about",
	},
	{
		path: "/about",
		name: "about",
		component: () => import(/* webpackChunkName: "about" */ "~/pages/About.vue").then((m) => m.default || m),
	},
	{
		path: "/business-partner",
		name: "business-partner",
		component: () => import(/* webpackChunkName: "about" */ "~/pages/BusinessPartner.vue").then((m) => m.default || m),
	},
	{
		path: "/business-partner/varane",
		name: "varane",
		component: () => import(/* webpackChunkName: "about" */ "~/pages/Varane.vue").then((m) => m.default || m),
	},
	{
		path: "/business-partner/autochartist",
		name: "autochartist",
		component: () => import(/* webpackChunkName: "about" */ "~/pages/Autochartist.vue").then((m) => m.default || m),
	},
	{
		path: "/copy-trading",
		name: "copy-trading",
		component: () => import(/* webpackChunkName: "about" */ "~/pages/CopyTrading.vue").then((m) => m.default || m),
	},
	{
		path: "/mam-pro-system",
		name: "mam-pro-system",
		component: () => import(/* webpackChunkName: "about" */ "~/pages/MamProSystem.vue").then((m) => m.default || m),
	},
	{
		path: "/trading/account-types/c-:classification?",
		name: "account-types-classification",
		component: () => import(/* webpackChunkName: "account-types" */ "~/pages/AccountTypes.vue").then((m) => m.default || m),
	},
	{
		path: "/trading/account-types",
		name: "account-types",
		component: () => import(/* webpackChunkName: "account-types" */ "~/pages/AccountTypes.vue").then((m) => m.default || m),
	},
	{
		path: "/trading/account-types/:type",
		name: "account-types-details",
		component: () => import(/* webpackChunkName: "account-types" */ "~/pages/AccountTypesDetails.vue").then((m) => m.default || m),
	},
	{
		name: "swaps",
		path: "/market/swaps",
		component: () => import(/* webpackChunkName: "Markets" */ "~/pages/Swaps").then((m) => m.default || m),
	},
	{
		name: "swap-free",
		path: "/market/swaps-free",
		component: () => import(/* webpackChunkName: "Markets" */ "~/pages/SwapFree").then((m) => m.default || m),
	},
	{
		name: "market-details",
		path: "/market/:category?/:slug/instrument-:id-:type",
		component: () => import(/* webpackChunkName: "Markets" */ "~/pages/MarketDetails").then((m) => m.default || m),
	},
	{
		name: "market",
		path: "/market/:category?/:slug",
		component: () => import(/* webpackChunkName: "Markets" */ "~/pages/Market").then((m) => m.default || m),
	},
	/** ************** partnerships **************** */
	{
		name: "overview",
		path: "/trading/partnerships",
		component: () => import(/* webpackChunkName: "Partnerships" */ "~/pages/Overview").then((m) => m.default || m),
	},
	{
		name: "cpa-affiliate-partnership",
		path: "/partnerships/affiliate",
		component: () => import(/* webpackChunkName: "Partnerships" */ "~/pages/CpaAffiliatePartnership").then((m) => m.default || m),
	},
	{
		name: "introducing-broker",
		path: "/partnerships/agent",
		component: () => import(/* webpackChunkName: "Partnerships" */ "~/pages/IntroducingBroker").then((m) => m.default || m),
	},
	{
		name: "referral-partnership",
		path: "/partnerships/referral",
		component: () => import(/* webpackChunkName: "Partnerships" */ "~/pages/ReferralPartnership").then((m) => m.default || m),
	},
	/** ****************************** */
	{
		path: "/news/blogs-and-trends",
		name: "blogs-and-trends",
		component: () => import(/* webpackChunkName: "account-types" */ "~/pages/News/BlogsAnadTrends.vue").then((m) => m.default || m),
	},
	{
		path: "/news/blogs-and-trends-details/:slug",
		name: "blogs-and-trends-details",
		component: () =>
			import(/* webpackChunkName: "account-types" */ "~/pages/News/BlogsAnadTrendsDetails.vue").then((m) => m.default || m),
	},
	{
		name: "faq-list",
		path: "/faqs",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/FAQ").then((m) => m.default || m),
		children: [
			{
				name: "faq",
				path: "faqs/:category?",
				component: () => import(/* webpackChunkName: "news" */ "~/pages/FAQDetails").then((m) => m.default || m),
			},
		],
	},
	{
		name: "educational",
		path: "/educational",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/Educational").then((m) => m.default || m),
	},

	{
		name: "trading-platforms",
		path: "/trading-platforms",
		component: () => import(/* webpackChunkName: "tools" */ "~/pages/TradingPlatforms").then((m) => m.default || m),
	},
	{
		name: "deposits-and-withdrawals-old",
		path: "/trading/deposits-and-withdrawals",
		redirect: "/deposits-and-withdrawals",
	},
	{
		name: "deposits-and-withdrawals",
		path: "/deposits-and-withdrawals",
		component: () => import(/* webpackChunkName: "tools" */ "~/pages/DepositsAndWithdrawals").then((m) => m.default || m),
	},
	{
		name: "calculator",
		path: "/calculator",
		component: () => import(/* webpackChunkName: "tools" */ "~/pages/Calculator").then((m) => m.default || m),
	},
	{
		name: "glossary",
		path: "/glossary",
		component: () => import(/* webpackChunkName: "Help" */ "~/pages/Glossary").then((m) => m.default || m),
	},
	{
		name: "video-details",
		path: "/educational/videos/:category/:slug",
		component: () => import(/* webpackChunkName: "Help" */ "~/pages/VideoDetails").then((m) => m.default || m),
	},
	{
		name: "videos",
		path: "/educational/videos",
		component: () => import(/* webpackChunkName: "Help" */ "~/pages/VideosList").then((m) => m.default || m),
	},
	{
		name: "article-details",
		path: "/educational/articles/:slug",
		component: () => import(/* webpackChunkName: "Help" */ "~/pages/ArticleDetails").then((m) => m.default || m),
	},
	{
		name: "articles",
		path: "/educational/articles",
		component: () => import(/* webpackChunkName: "Help" */ "~/pages/ArticlesList").then((m) => m.default || m),
	},

	{
		name: "webinars",
		path: "/educational/webinars",
		component: () => import(/* webpackChunkName: "Help" */ "~/pages/Webinars").then((m) => m.default || m),
	},
	{
		name: "webinar-details",
		path: "/educational/webinars/:slug",
		component: () => import(/* webpackChunkName: "Help" */ "~/pages/WebinarDetails").then((m) => m.default || m),
	},
	{
		name: "contact-us-old",
		path: "/about/contact-us",
		redirect: "/contact-us",
	},
	{
		name: "contact-us",
		path: "/contact-us",
		component: () => import(/* webpackChunkName: "Footer" */ "~/pages/ContactUs").then((m) => m.default || m),
	},
	{
		name: "regulation-old",
		path: "/about/regulation",
		redirect: "/regulation",
	},
	{
		name: "regulation",
		path: "/regulation",
		component: () => import(/* webpackChunkName: "Footer" */ "~/pages/Regulation").then((m) => m.default || m),
	},
	{
		name: "legal-documents-old",
		path: "/about/legal-forms",
		redirect: "/legal-documents",
	},
	{
		name: "legal-document-details",
		path: "/legal-documents/:key",
		component: () => import(/* webpackChunkName: "Footer" */ "~/pages/LegalDocumentDetails").then((m) => m.default || m),
	},
	{
		name: "legal-documents",
		path: "/legal-documents",
		component: () => import(/* webpackChunkName: "Footer" */ "~/pages/LegalDocuments").then((m) => m.default || m),
	},

	{
		name: "careers",
		path: "careers",
		component: () => import(/* webpackChunkName: "Footer" */ "~/pages/Careers").then((m) => m.default || m),
	},
	{
		name: "old-newsroom",
		path: "/about/newsroom/:slug?",
		redirect: "/news/newsroom/:slug?",
	},
	{
		name: "newsroom",
		path: "/news/newsroom",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/NewsRoom").then((m) => m.default || m),
	},
	{
		name: "newsroom-details",
		path: "/news/newsroom/:slug",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/NewsRoomDetails").then((m) => m.default || m),
	},
	{
		name: "old-economic-calendar",
		path: "/trading/economic-calendar",
		redirect: "/news/global-events/economic-calendar",
	},
	{
		name: "economic-calendar",
		path: "/news/global-events/economic-calendar",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/EconomicCalendar").then((m) => m.default || m),
	},
	{
		name: "old-corporate-events",
		path: "/market-alerts/corporate-events",
		redirect: "/news/global-events/corporate-events",
	},
	{
		name: "corporate-events",
		path: "/news/global-events/corporate-events",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/CorporateEvents").then((m) => m.default || m),
	},
	{
		name: "market-news-redirect",
		path: "/market-alerts/market-news/:type/:slug",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/RedirectAnnouncementsAndHolidays").then((m) => m.default || m),
	},
	{
		name: "market-news-redirect-without-params",
		path: "/market-alerts/market-news",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/AnnouncementsAndHolidays").then((m) => m.default || m),
	},
	{
		name: "announcements-and-holidays",
		path: "/news/global-events/announcements-and-holidays",
		component: () => import(/* webpackChunkName: "news" */ "~/pages/AnnouncementsAndHolidays").then((m) => m.default || m),
	},
	{
		name: "signature",
		path: "/online-forms/signature/:type?",
		component: () => import(/* webpackChunkName: "online-forms" */ "~/pages/Signature").then((m) => m.default || m),
	},
	{
		name: "exchanges-agreement",
		path: "/foreign-stock-exchanges-agreement",
		// component: () => import(/* webpackChunkName: "online-forms" */ "~/pages/ExchangesAgreement").then((m) => m.default || m),
		redirect: "/legal-documents/financial-brokerage-agreement-for-foreign-exchanges",
	},
	{
		name: "campaigns",
		path: "/campaign/:slug/:slug2?",
		component: () => import(/* webpackChunkName: "Campaigns" */ "~/pages/Campaigns.vue").then((m) => m.default || m),
	},
	{
		name: "privacy-policy",
		path: "/privacy-policy",
		component: () => import(/* webpackChunkName: "Auth" */ "~/pages/PrivacyPolicy.vue").then((m) => m.default || m),
	},
	{
		name: "gold-campaign",
		path: "/landing/gold-campaign",
		component: () => import(/* webpackChunkName: "gold-campaign" */ "~/pages/GoldCampaign").then((m) => m.default || m),
	},
];
