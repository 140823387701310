// import Vue from "vue";
// import Vuetify from "vuetify/lib";
// import Toasts from "../components/Toasts/index.vue";
// const ToastConstructor = Vue.extend(Toasts);
import { mdiClose, mdiCheck, mdiAlertCircle ,mdiAlert,mdiInformation} from "@mdi/js";

const globalOptions = {
	right: true, // default
	bottom: true, // default
	color: "info", // default
	icon: "mdi-information", // default
	iconColor: "", // default
	classes: ["body-2"],
	timeout: 3000, // default
	dismissable: true, // default
	multiLine: false, // default
	vertical: false, // default
	queueable: false, // default
	showClose: false, // default
	closeText: "", // default
	closeIcon: mdiClose, // default
	closeColor: "", // default
	slot: [], // default
	shorts: {
		success: {
			color: "success",
			icon: mdiCheck,
		},
		error: {
			color: "error",
			icon: mdiAlertCircle,
			timeout: 0,
			showClose: true,
		},
		warning: {
			color: "warning",
			icon: mdiAlert,
			timeout: 10000,
			showClose: true,
		},
		info: {
			color: "info",
			icon: mdiInformation,
			timeout: 4000,
			showClose: true,
		},
	},
	title: null,
};

let cmp = null;
const queue = [];
// const property = globalOptions.property || "$toast";

// function createCmp(options) {
// 	const component = new ToastConstructor();
// 	const vuetifyObj = new Vuetify();
// 	component.$vuetify = vuetifyObj.framework;
// 	const componentOptions = { ...globalOptions, ...options };

// 	if (componentOptions.slot) {
// 		component.$slots.default = componentOptions.slot;
// 		delete componentOptions.slot;
// 	}

// 	Object.assign(component, componentOptions);

// 	// find class v-application in body
// 	const app = document.getElementsByClassName("v-application")[0];
// 	app.appendChild(component.$mount().$el);

// 	// get vue #toast by id and use its method

// 	return component;
// }

function show(message, options = {}) {
	if (!cmp) {
		const toast = document.getElementById("toast");
		cmp = toast?.__vue__;
	}
	// cmp = createCmp(options);

	options.message = message;
	cmp.add({ ...globalOptions, ...options });
	// console.dir(cmp);
}

function shorts(options) {
	const colors = ["success", "info", "error", "warning"];
	const methods = {};

	colors.forEach((color) => {
		methods[color] = (message, options) => show(message, { color, ...options });
	});

	if (options.shorts) {
		for (const key in options.shorts) {
			const localOptions = options.shorts[key];
			methods[key] = (message, options) => show(message, { ...localOptions, ...options });
		}
	}

	return methods;
}

function getCmp() {
	return cmp;
}

function clearQueue() {
	return queue.splice(0, queue.length);
}

export default (_context, inject) => {
	const toast = { globalOptions, getCmp, clearQueue };
	const methods = shorts(globalOptions);
	for (const key in methods) {
		toast[key] = methods[key];
	}
	inject("toast", toast);
};
