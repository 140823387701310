
import { VCard, VCardActions, VCardText, VCardTitle, VDialog, VIcon, VSpacer, VBtn } from "vuetify/lib";

export default {
	components: {
		VCard,
		VCardActions,
		VCardText,
		VCardTitle,
		VDialog,
		VIcon,
		VSpacer,
		VBtn,
	},
	props: {
		buttonTrueText: {
			type: String,
			default: "Yes",
		},
		buttonFalseText: {
			type: String,
			default: "No",
		},
		buttonTrueColor: {
			type: String,
			default: "error",
		},
		buttonFalseColor: {
			type: String,
			default: "grey",
		},
		buttonFalseFlat: {
			type: Boolean,
			default: true,
		},
		buttonTrueFlat: {
			type: Boolean,
			default: true,
		},
		color: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default() {
				return null; // this.$vuetify.icons.values.warning;
			},
		},
		message: {
			type: String,
			required: true,
		},
		persistent: Boolean,
		title: {
			type: String,
			default: null,
		},
		width: {
			type: Number,
			default: 450,
		},
	},
	data() {
		return {
			value: false,
		};
	},
	beforeMount() {
		this.$nuxt.$emit("confirm-box", { value: true, id: this._uid });
	},
	mounted() {
		document.addEventListener("keyup", this.onEnterPressed);
	},
	destroyed() {
		document.removeEventListener("keyup", this.onEnterPressed);
		// setTimeout(() => {
		this.$nuxt.$emit("confirm-box", { value: false, id: this._uid });
		// }, 500);
	},
	methods: {
		onEnterPressed(e) {
			if (e.keyCode === 13) {
				e.stopPropagation();
				this.choose(true);
			}
		},
		choose(value) {
			this.$emit("result", value);
			this.value = value;
			this.$destroy();
		},
		change(res) {
			this.$destroy();
		},
	},
};
